<template>
  <b-modal id="edit-settings" :title="mTitle" :ok-disabled="true" :cancel-disabled="true" @show="getSettings">
    <template v-if="!loading">
      <select-dropdown :is-disabled="loading" :items="years" v-model="year"></select-dropdown>
      <table>
        <thead>
          <tr>
            <th class="_name">&nbsp;</th>
            <th v-for="month in months">{{ getMonthName(month) }}</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(group, groupKey) in groups">
            <tr>
              <td class="_name" colspan="13" style="border: 0; text-align: left">
                <b>{{ group.name }}</b>
              </td>
            </tr>
            <tr v-for="(item, itemKey) in group.items">
              <td class="_name">{{ item.name }}</td>
              <td v-for="month in months">
                <input type="text" v-model="groups[groupKey].items[itemKey].dates[`${year}-${month}-01`]" />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div class="submit">
        <button type="submit" @click.prevent="setSettings" class="btn btn-primary" :disabled="loading">
          {{ loading ? 'Выполняется...' : mOkTitle }}
        </button>
        <button
          class="btn btn-default"
          :disabled="loading"
          @click.prevent="$root.$emit('bv::hide::modal', 'edit-settings')"
        >
          Отмена
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import api from '../../assets/js/api'
  import queryHelper from '../../assets/js/query'

  import range from 'lodash/range'
  import map from 'lodash/map'
  import get from 'lodash/get'
  import each from 'lodash/each'
  import cloneDeep from 'lodash/cloneDeep'
  import SelectDropdown from '../../components/SelectDropdown'
  import moment from 'moment'

  export default {
    name: 'edit-settings',
    components: { SelectDropdown },
    props: ['module'],
    computed: {
      years() {
        return map(range(2015, 2025), y => {
          return {
            name: y.toString(),
            value: y,
          }
        })
      },
      mTitle() {
        return `Настройка мотиваций`
      },
      mOkTitle() {
        return 'Сохранить настройки'
      },
    },
    watch: {
      year(val) {
        this.getSettings()
      },
    },
    data() {
      return {
        groups: {
          full_output: {
            name: 'full_output',
            items: {
              hours: { name: 'hours', dates: {} },
              percent: { name: 'percent', dates: {} },
            },
          },
          position_premium: {
            name: 'position_premium',
            items: {
              bonus_fund: { name: 'bonus_fund', dates: {} },
              date_bonus: { name: 'date_bonus', dates: {} },
              time_bonus: { name: 'time_bonus', dates: {} },
              tuner_bonus: { name: 'tuner_bonus', dates: {} },
              manager_bonus: { name: 'manager_bonus', dates: {} },
              max_days_overdue_penalty: { name: 'max_days_overdue_penalty', dates: {} },
            },
          },
        },
        year: moment().format('YYYY'),
        months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        loading: true,
      }
    },
    created() {},
    methods: {
      get,
      setEmptySettings() {
        let dates = {}

        each(this.months, month => {
          dates[`${this.year}-${month}-01`] = ''
        })

        this.$set(this, 'groups', {
          full_output: {
            name: 'full_output',
            items: {
              hours: { name: 'hours', dates: cloneDeep(dates) },
              percent: { name: 'percent', dates: cloneDeep(dates) },
            },
          },
          position_premium: {
            name: 'position_premium',
            items: {
              bonus_fund: { name: 'bonus_fund', dates: cloneDeep(dates) },
              date_bonus: { name: 'date_bonus', dates: cloneDeep(dates) },
              time_bonus: { name: 'time_bonus', dates: cloneDeep(dates) },
              tuner_bonus: { name: 'tuner_bonus', dates: cloneDeep(dates) },
              manager_bonus: { name: 'manager_bonus', dates: cloneDeep(dates) },
              max_days_overdue_penalty: { name: 'max_days_overdue_penalty', dates: cloneDeep(dates) },
            },
          },
        })
      },
      getSettings() {
        this.loading = true

        this.setEmptySettings()

        api.http.get(`/motivations/settings/${this.year}`).then(
          response => {
            each(response.data, (a, date) => {
              each(a, (b, groupKey) => {
                each(b, (val, itemKey) => {
                  this.$set(this.groups[groupKey].items[itemKey].dates, date, val)
                })
              })
            })

            this.loading = false
          },
          err => {
            alert(err)

            this.loading = false
          }
        )
      },
      setSettings() {
        api.http.put(`/motivations/settings/${this.year}`, { groups: this.groups }).then(
          response => {
            this.$root.$emit('bv::hide::modal', 'edit-motivation')
          },
          err => {
            alert(err)
          }
        )
      },
      getMonthName(month) {
        return moment(month + '-2018', 'MM-YYYY').format('MMM')
      },
    },
  }
</script>

<style lang="scss">
  #edit-settings .modal-dialog {
    max-width: 840px !important;
  }
  #edit-settings .modal-footer {
    display: none !important; /* я не знаю как выключить кнопки и возможно ли это*/
  }
</style>

<style lang="scss" scoped>
  .b-dropdown {
    width: 150px !important;
  }
  table {
    table-layout: fixed;
    margin: 2rem 0 0 0;

    th,
    td {
      border: 1px solid #ddd;
      text-align: center;
      width: 50px;
      min-width: 50px;
      max-width: 50px;

      &._name {
        width: auto;
        min-width: 200px;
        padding: 5px 10px;
        text-align: left;
      }
    }

    input {
      width: 100%;
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      border: 0;
      text-align: center;
    }
  }
  .submit {
    padding: 2rem 0 0 0;

    .btn {
      margin: 0 1rem 0 0;
    }
  }
</style>
